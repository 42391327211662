/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Theme from "./theme"
import Footer from "./footer"
import CTA from "./cta"

const Layout = ({ children }) => (
  <>
    <Theme>
      <CTA></CTA>
      <main>{children}</main>
      <Footer />
    </Theme>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
